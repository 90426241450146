<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="2">
            <div role="group" class="projectSelect form-group">
              <select id="yearMonth" class="form-control" v-model="selectDaily">
                <option disabled value="0">帳單月</option>
                <option
                  :data-key="value"
                  v-for="(value, key) in SelectSixMonth"
                  :key="key"
                >
                  {{ value.month }}
                </option>
              </select>
            </div>
          </CCol>
          <CCol sm="3">
            <div role="group" class="projectSelect form-group">
              <select id="yearMonth" class="form-control" v-model="company">
                <option disabled value="0">公司</option>
                <option
                  :data-key="value"
                  v-for="(value, key) in companyList"
                  :key="key"
                >
                  {{ value.name }}
                </option>
              </select>
            </div>
          </CCol>
          <CCol sm="3">
            <div role="group" class="projectSelect form-group">
              <!-- <label for="uid-ksovz61x97" class=""> 月份 </label> -->
              <select id="yearMonth" class="form-control" v-model="ba">
                <option disabled value="0">BA</option>
                <option
                  :data-key="value"
                  v-for="(value, key) in baList"
                  :key="key"
                >
                  {{ value.name }}
                </option>
              </select>
            </div>
          </CCol>
          <CCol sm="2">
            <div role="group" class="projectSelect form-group">
              <!-- <label for="uid-ksovz61x97" class=""> 月份 </label> -->
              <select id="yearMonth" class="form-control" v-model="project">
                <option disabled value="0">Project</option>
                <option
                  :data-key="value"
                  v-for="(value, key) in projectList"
                  :key="key"
                >
                  {{ value.name }}
                </option>
              </select>
            </div>
          </CCol>
          <CCol sm="2">
            <div role="group" class="projectSelect form-group">
              <!-- <label for="uid-ksovz61x97" class=""> 月份 </label> -->
              <select id="yearMonth" class="form-control" v-model="service">
                <option disabled value="0">Service</option>
                <option
                  :data-key="value"
                  v-for="(value, key) in serviceList"
                  :key="key"
                >
                  {{ value.name }}
                </option>
              </select>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol sm="6">
            <span class="titleFont">費用趨勢</span>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <MainChartLine :inputDate="selectDaily" />
      </CCardBody>
      <CCardFooter style="border: none" class="pcDisplay">
        <div style="visibility: hidden"></div>
      </CCardFooter>
    </CCard>
    <div class="borderDA2"></div>
    <CRow>
      <CCol sm="6" lg="6">
        <CCard>
          <CCardHeader>{{ yearMonth }}總費用</CCardHeader>
          <CCardBody class="cardCost">$0.00</CCardBody>
          <!-- <CCardFooter>3</CCardFooter> -->
        </CCard>
      </CCol>
      <CCol sm="6" lg="6">
        <CCard>
          <CCardHeader> 預測總費用</CCardHeader>
          <CCardBody class="cardCost">$0.00</CCardBody>
          <!-- <CCardFooter>3</CCardFooter> -->
        </CCard>
      </CCol>
      <!-- <CCol sm="2" lg="2">
        <CCard>
          <CCardHeader>Invoice</CCardHeader>
          <CCardBody style="padding:0.9rem; margin:auto;">
            <CButton block variant="outline" color="dark" class="invoiceBtn">
              <CIcon name="cil-cloud-download" />
            </CButton>
          </CCardBody>
        </CCard>
      </CCol> -->
    </CRow>
    <!-- 共用錯誤彈窗 -->
    <Modal></Modal>
  </div>
</template>
<script>
import MainChartLine from './charts/MainChartLine.vue'
import Modal from './Modal.vue'
export default {
  data() {
    return {
      selectDaily: 0,
      SelectSixMonth: [
        { month: '202205' },
        { month: '202204' },
        { month: '202203' },
      ],
      company: '0',
      companyList: [
        { name: 'ECV' },
        { name: 'CompanyA' },
        { name: 'CompanyB' },
      ],
      ba: '0',
      baList: [
        { name: 'ba2020254941' },
        { name: 'ba2020256524' },
        { name: 'ba2020258524' },
      ],
      project: '0',
      projectList: [
        { name: 'pro2020254941' },
        { name: 'pro2020256524' },
        { name: 'pro2020258524' },
      ],
      service: '0',
      serviceList: [
        { name: 'pro2020254941' },
        { name: 'pro2020256524' },
        { name: 'pro2020258524' },
      ],
      yearMonth: '',
    }
  },
  components: {
    Modal,
    MainChartLine,
  },
  mounted() {
    this.yearMonth = this.common.getMonth()
  },
}
</script>

<template>
  <div id="fullDashLine" class="bgColor_w">
    <!-- 手機板放大按鈕 -->
    <!-- <div class="fullDiv">
      <CButton
        block
        variant="outline"
        color="dark"
        class="fullScreenButton"
        :class="isFull ? 'fullButtonAll' : 'fullButton'"
        @click="fullScreenEvent('chartLine')"
      >
        <CIcon
          :name="isFull ? 'cilFullscreenExit' : 'cilFullscreen'"
          class="icon"
        />
      </CButton>
    </div> -->
    <CChartLine
      :datasets="defaultDatasets"
      :labels="chartLineDay"
      :options="defaultOptions"
    />
    <!-- 共用錯誤彈窗 -->
    <Modal></Modal>
  </div>
</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils/src'
// import Bus from '../../bus.js';
function random(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}
import { mapGetters, mapMutations } from 'vuex'
import Modal from '../Modal.vue'
export default {
  name: 'MainChartLine',
  data() {
    return {
      // datas: '',
      chartLineDay: '',
      isFull: false,
      getYearMonth: '',
    }
  },
  props: {
    inputDate: '',
  },
  components: { CChartLine, Modal },
  computed: {
    ...mapGetters(['get_dailyAmount']),
    defaultDatasets() {
      const brandSuccess = getStyle('success2') || '#4dbd74'
      const brandInfo = getStyle('info') || '#20a8d8'
      const brandDanger = getStyle('danger') || '#f86c6b'
      let elements = 31
      // const data1 = [];
      // const data2 = [];
      const data3 = []
      for (let i = 0; i <= elements; i++) {
        // data1.push(random(50, 200));
        // data2.push(random(80, 100));
        data3.push(80)
      }
      return [
        {
          label: '專案',
          backgroundColor: hexToRgba(brandInfo, 10),
          borderColor: brandInfo,
          pointHoverBackgroundColor: brandInfo,
          borderWidth: 2,
          data: [
            50,
            53,
            58,
            51,
            45,
            45,
            49,
            43,
            47,
            52,
            50,
            49,
            45,
            45,
            50,
            52,
            53,
            58,
            51,
            45,
            42,
            45,
            45,
            41,
            38,
            41,
            44,
            49,
            51,
            53,
            49,
          ],
        },
        {
          label: '服務',
          backgroundColor: 'transparent',
          borderColor: brandSuccess,
          pointHoverBackgroundColor: brandSuccess,
          borderWidth: 2,
          data: [
            49,
            62,
            40,
            45,
            40,
            48,
            45,
            45,
            42,
            55,
            51,
            57,
            51,
            53,
            50,
            49,
            45,
            49,
            52,
            56,
            58,
            58,
            51,
            49,
            45,
            49,
            51,
            55,
            55,
            50,
            48,
          ],
        },
        // {
        //   label: 'Alert',
        //   backgroundColor: 'transparent',
        //   borderColor: brandDanger,
        //   pointHoverBackgroundColor: brandDanger,
        //   borderWidth: 1,
        //   borderDash: [8, 5],
        //   data: data3,
        // },
      ]
    },
    defaultOptions() {
      return {
        responsive: true,
        maintainAspectRatio: true,
        maxTicksLimit: 100,

        // 畫布縱橫比
        // aspectRatio: 2,
        legend: {
          display: false,
        },

        scales: {
          xAxes: [
            {
              // 直線
              gridLines: {
                drawOnChartArea: true,
              },
              ticks: {},
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 50,
                stepSize: 1,
                max: 100,
                min: 0,
              },
              // 橫線
              gridLines: {
                display: true,
              },
            },
          ],
        },
        elements: {
          point: {
            radius: 1,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3,
          },
          line: {
            tension: 0, // 禁用贝貝茲曲線
          },
        },
      }
    },
  },
  mounted() {
    this.chartLineDay = this.common.getChartDate()
  },
  watch: {
    inputDate() {
      //   console.log('inputDate', this.inputDate)
      let yearMonth = this.inputDate
      //   this.$store.dispatch('actionChartDaily', yearMonth)
    },
  },
  methods: {
    fullScreenEvent(type) {
      //   console.log('type', type)
      this.$emit('type', type)
      this.isFull = !this.isFull
    },
  },

  created() {
    this.getYearMonth = this.common.getMonth()
    console.log('hello 月份', this.getYearMonth)
    let yearMonth = this.getYearMonth

    // this.$store.dispatch('actionChartDaily', yearMonth)
  },
}
</script>
